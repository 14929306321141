<template>
    <img :src="link">
</template>

<script>
import { storage } from '@/firebase'

export default {
    props: [
        'filename'
    ],
    data() {
        return {
            link: null
        }
    },
    created() {
        storage.ref(`products/${this.filename}`).getDownloadURL().then(url => {
            this.link = url;
        });
    }
}
</script>